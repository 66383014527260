<template>
    <grid-view 
        title="Задания"
        filter
        create
        create-title="Новое задание"
        url-create="/task/create"
        url-update="/task/update/:id"
        store-module="task"
        :query-filters="['subject', 'grade']"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Обновлен', value: 'updated_at_formatted', width: 1, sortable: false },
            { text: 'Метка загрузки', value: 'tag', width: 1, sortable: false },
            { text: 'Календарный год', value: 'year', width: 1, sortable: false },
            { text: 'Предмет', value: 'subject', width: 1, sortable: false },
            { text: 'Класс', value: 'grade', width: 1, sortable: false },
            { text: 'Вариант', value: 'option', width: 1, sortable: true },
            { text: 'Номер', value: 'task', width: 1, sortable: true },
            { text: 'Итоговая работа', value: 'is_final', width: 1, sortable: false },
            // { text: 'Название', value: 'name', width: 1 },
            // { text: 'Полное название', value: 'full_name', sortable: false },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false },
            { text: '', value: 'create_similar', width: 1, sortable: false }
        ]"
        :format="formatItems"
        :data-table-options="{
            'show-select': true,
        }"
    >
        <!-- Extra buttons in title -->
        <template #title.right>
            <v-btn @click="popup.tasksScoresReport.show = true" :loading="loading" :disabled="loading" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-download
                </v-icon>
                <span>Выгрузка оценок</span>
            </v-btn>

            <!-- Delete selected items confirm dialog -->
            <!-- TODO: перенести форму из попапа на отдельную страницу на манер с функционалом остальных кнопок -->
            <v-dialog v-model="popup.tasksScoresReport.show" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Выгрузка оценок заданий</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="popup.tasksScoresReport.data.year"
                            label="Год"
                            type="number" 
                            :color="$const.color.primary"
                        ></v-text-field>
                     
                        <v-select
                            v-model="popup.tasksScoresReport.data.subject"
                            :items="subjects"
                            label="Предмет"
                        ></v-select>
                        <v-text-field
                            v-model="popup.tasksScoresReport.data.grade"
                            label="Класс"
                            type="number"
                            :color="$const.color.primary"
                        ></v-text-field>
                        <v-checkbox
                            v-model="popup.tasksScoresReport.data.is_final"
                            label="Итоговая работа"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark :color="$const.color.primary" @click="popup.tasksScoresReport.show = false">Отмена</v-btn>
                        <v-btn outlined :disabled="!canRequestTasksScoresReport" @click="downloadTasksScoresReport">Скачать</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-btn to="/task/themes-upload" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-upload
                </v-icon>
                <span>Загрузить темы</span>
            </v-btn>
            <v-btn to="/task/upload" :color="$const.color.primary" class="ml-auto mr-2 white--text">
                <v-icon left dark>
                    mdi-upload
                </v-icon>
                <span>Загрузить задание(я)</span>
            </v-btn>
        </template>
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.year"
                        label="Календарный год" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <!-- <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.grade"
                        label="Класс" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.subject"
                        label="Предмет" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col> -->

                 <v-col lg="6" sm="12">
                    <v-select
                      v-model="filter.is_final"
                      :items="[{
                        text: 'Все',
                        value: null
                      },{
                        text: 'Нет',
                        value: 0
                      },{
                        text: 'Да',
                        value: 1
                      }]"
                      label="Итоговая работа"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.option"
                        label="Вариант" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.name"
                        label="Название" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.tag"
                        label="Метка загрузки" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.task"
                        label="Номер задания" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
import ApiHelper from '@/helpers/ApiHelper'

export default {
    name: 'TaskIndex',
    components: { GridView }, 
    data() {
        return {
            loading: false,
            popup: {
                tasksScoresReport: {
                    show: false,
                    data: {
                        year: (new Date()).getFullYear(),
                        subject: '',
                        grade: 4,
                        is_final: false
                    }
                }
            }
        }
    },
    computed: {
        subjects () {
            return this.$store.state.task.subjects.map(s => s.text);
        },
        canRequestTasksScoresReport () {
            return  this.popup.tasksScoresReport.data.grade &&
                    this.popup.tasksScoresReport.data.subject &&
                    this.popup.tasksScoresReport.data.year
        }
    },
    methods: {
        async downloadTasksScoresReport () {
            if (this.loading) { return false; }
            try {
                this.loading = true;
                this.popup.tasksScoresReport.show = false;
                const { data } = await ApiHelper.request('/api/report/tasks-scores-list', 'get', {}, this.popup.tasksScoresReport.data, {}, 'blob');

                let exportUrl = URL.createObjectURL(data);
                window.location.assign(exportUrl);
                URL.revokeObjectURL(exportUrl);
            } catch (e) {
                console.error(e)
                alert('Ошибка формирования файла.')
            }
            finally {
                this.loading = false;
            }
        },
        formatItems (items) {
            return _.map(items, (item) => {
                item['is_final'] = item.is_final ? 'Да' : 'Нет';
                
                const dateUpdatedAt = new Date(item.updated_at * 1000);

                item['updated_at_formatted'] = _.join([
                    dateUpdatedAt.getDate(),
                    (dateUpdatedAt.getMonth() < 9 ? '0' : '') + (dateUpdatedAt.getMonth() + 1).toString(),
                    dateUpdatedAt.getFullYear()
                ], '.');

                return item;
            })
        } 
    }
}
</script>