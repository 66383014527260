<template>
    <v-dialog v-model="value" persistent max-width="600px">
        <v-card>
            <v-toolbar dark :color="$const.color.primary">
                <v-toolbar-title>Фильтр</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <slot :model="model" />
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red" dark @click.prevent.stop="reset">
                    Сбросить
                </v-btn>
                
                <v-spacer></v-spacer>

                <v-btn outlined color="warning" @click.prevent.stop="close">
                    Закрыть
                </v-btn>
                <v-btn :color="$const.color.primary" dark @click.prevent.stop="apply">
                    Применить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'GridView',
    props: {
        value: Boolean,
        storeModule: String,
        // Если переданы строковые значения в массиве, то указанные query в route будут отслеживаться
        // и найденные значения будут использоваться в фильтре при запросе к данным
        queryFilters: { type: Array, default: () => [] }
    },
    data() {
        return {
            loading: true,
            model: {}
        }
    },
    watch: {
        '$route.query': {
            handler () {
                if (!this.queryFilters.length) { return false; }
                this.fetch();
            },
            deep: true
        },
    },
    methods: {
        fetch() {
            const filter = _.get(this, `$store.state.${this.storeModule}.filter`, {});
            _.assign(
                this.model,
                _.cloneDeep(filter),
                _.pick(this.$route.query, this.queryFilters)
            );
            // Making queryFilters keys from undefined to null is important for filtering
            this.queryFilters.forEach(key => {
                if (this.model[key] === undefined) {
                    this.model[key] = null
                }
            })
        },
        async apply() {
            this.close();
            this.loading = true;
            // Skip values with empty strings
            Object.keys(this.model).forEach(key => {
                if (this.model[key] === "") { this.model[key] = null; }
            })
            await this.$store.dispatch(`${this.storeModule}/filter`, this.model);
            this.$emit('filter', 1);

            this.loading = false;
        },
        async reset() {
            this.loading = true;
            
            await this.$store.dispatch(`${this.storeModule}/reset`);
            this.fetch();
            this.apply();
        },
        close() {
            return this.$emit('close', 1);
        }
    },
    created() {
        this.fetch();
    }
}
</script>
<style lang="scss">
</style>